<template>
  <b-card>
    <div>
      <b-row>
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start pb-1"
        >
          <b-button
            variant="primary"
            :to="{ name: 'apps-invoice-add'}"
          >
            Add Invoice
          </b-button>
        </b-col>
        <!-- input search -->
        <b-col
          cols="6"
          md="6"
        >
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <!-- alert -->
          <b-alert
            v-height-fade.appear
            variant="danger"
            :show="showError"
            class="text-left p-1"
          >
            <ul class="list-style-icons mb-0">
              <li><feather-icon icon="ArrowRightIcon" />{{ apiErrors }}</li>
            </ul>
          </b-alert>
        </b-col>
        <b-col cols="3" />
        <b-col cols="3" />
      </b-row>

      <b-overlay
        :show="showOverLay"
        rounded="sm"
        :opacity="0.5"
      >
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          responsive
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: date -->
            <span v-if="props.column.field === 'date'">
              {{ new Date(props.row.date).toLocaleDateString() }}
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    :to="{ name: 'apps-invoice-preview', params: { id: props.row.id } }"
                  >
                    <feather-icon
                      icon="PrinterIcon"
                      class="mr-50"
                    />
                    <span>Print</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="{ name: 'apps-invoice-edit', params: { id: props.row.id } }"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="confirmText(props.row.id, $store.state.appConfig.layout.isRTL ? props.row.name_ar : props.row.name_en)">
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10','50','100']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-overlay>
    </div>
  </b-card>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BButton, BRow, BCol, BAlert, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import operation from '@/service/warehouse/operation'
import { heightFade } from '@core/directives/animations'
import supplier from '@/service/crm/supplier'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
    BAlert,
    BOverlay,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      vendor_set_option: [],
      columns: [
        {
          label: 'ID',
          field: 'current_document_no',
          filterOptions: {
            enabled: true,
            placeholder: 'Search ID',
          },
        },
        {
          label: 'Client',
          field: 'vedor_set.name_en',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Client',
          },
        },
        {
          label: 'Date',
          field: 'date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date',
          },
        },
        {
          label: 'Total Price',
          field: 'total_price',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Total Price',
          },
        },
        {
          label: 'Warehouse',
          field: 'warehouse.name_en',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Warehouse',
          },
        },
        {
          label: 'Document No',
          field: 'document_no',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Document No',
          },
        },
        {
          label: 'Description',
          field: 'description',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Description',
          },
        },
        {
          label: this.$t('General.Action'),
          field: 'action',
        },
      ],
      rows: [],
      oldRows: [],
      searchTerm: '',
      showError: false,
      showOverLay: false,
      apiErrors: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.layout.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async created() {
    this.getApi()
  },

  methods: {
    async confirmText(id, name) {
      this.$swal({
        title: 'Are you sure Delete?',
        text: `You will not be able to undo the deletion of this (${name})`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteApi(id).then(res => {
            if (res.data === 1) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: `${name} has been deleted.`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getApi()
            } else {
              this.$swal({
                icon: 'error',
                title: 'Deleted!',
                text: `${name} has been Not deleted.`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      })
    },
    async deleteApi(id) {
      this.showOverLay = true
      const resultData = await operation.deleteAxios(id).catch(error => {
        this.apiErrors = error
        this.showError = true
        this.showOverLay = false
        return error
      })
      this.showOverLay = false
      return resultData
    },
    async getApi() {
      this.showOverLay = true
      const resultVendorSet = await supplier.searchAxios(2).catch(error => {
        this.apiErrors = error
        this.showError = true
        return error
      })
      if (resultVendorSet.data) {
        this.vendor_set_option = resultVendorSet.data
        // eslint-disable-next-line prefer-destructuring
        // this.vendor_set_id = this.vendor_set_option[0]
        this.showError = false
      }
      const resultData = await operation.getAxios().catch(error => {
        this.apiErrors = error
        this.showError = true
        return error
      })
      if (resultData.data) {
        this.oldRows = resultData.data
        this.showError = false
      }
      setTimeout(() => {
        this.rows = this.oldRows.map(vendor => {
          // eslint-disable-next-line camelcase
          const vedor_set = this.vendor_set_option.find(x => x.id === vendor.vendor_set_id)
          return { ...vendor, vedor_set }
        })
      }, 500)
      this.showOverLay = false
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
